import React from "react"
import Carousel1 from "../../assets/img/carrousel-1.png";
import Carousel2 from "../../assets/img/carousel-2.png";
import Carousel3 from "../../assets/img/carousel-3.png";
import Back from "../../assets/img/back.svg";
import Next from "../../assets/img/next.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carrousel.scss";

const CustomDot = ({ onClick, ...rest }) => {
  const {
    active,
  } = rest;
  return (
    <div
      className={`Dot ${active ? 'Active' : ''}`}
      onClick={() => onClick()}
    >
    </div>
  );
};

const Carrousel = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return(<Carousel responsive={responsive}
                   customLeftArrow={<img className="Back" src={Back} alt={"Back"}/>}
                   customRightArrow={<img className="Next" src={Next} alt={"Next"}/>}
                   customDot={<CustomDot/>}
                   showDots={true}

  >
    <div className="Card">
      <img className="Avatar" src={Carousel3} alt="Afrimed Happy Customer"/>
      <div className="Author">
        Barbara Goto
      </div>
      <div className="Quote">
        Each day the number of people who wait to be seen by a doctor grows, some even stay
        in the queue for hours. AfriMed when detecting the problem, promised to reduce this
        number significantly. When someone in our family is needing care, it is comfortable
        to know that I can find a doctor at any time and anywhere, simply by accessing my mobile phone.
      </div>
    </div>
    <div className="Card">
      <img className="Avatar" src={Carousel1} alt="Afrimed Happy Customer"/>
      <div className="Author">
        Dr. P.D. Sibanda
      </div>
      <div className="Quote">
        Countries in development typically have a lack of infrastructure in the health sector,
        and as a doctor I can tell that mHealth is one of the most exciting developments in
        recent years. AfriMed is a new startup that promises to provide innovative care access
        and delivery models that will produce better results with reduced healthcare costs,
        safe, and innovative practices for patients.
      </div>
    </div>
    <div className="Card">
      <img className="Avatar" src={Carousel2} alt="National Insurance Agent"/>
      <div className="Author">
        National Insurance Company
      </div>
      <div className="Quote">
        Many people believe that mHealth will be the “next big thing”, a logical or perhaps
        a big revolution. However, what impacts the most, is that mHealth uses the benefit
        of digital transformation initiated by Regulatory, Payer, and Supplier interests only
        a few years ago. As an insurer, this will reduce the amount of paperwork we do for our customers.
      </div>
    </div>
  </Carousel>)
}

export default Carrousel;
