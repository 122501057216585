import React, { useEffect, useRef, useState } from "react"
import "@brainhubeu/react-carousel/lib/style.css"
import SEO from "../../components/seo/seo"
import Header from "../../components/header/header"
import Footer from "../../components/footer/footer"
import Contact from "../../components/contact/contact"
import MainImage from "../../assets/img/illustration-1@2x.png"
import Bkg1 from "../../assets/img/bkg-1.svg"
import Bkg2 from "../../assets/img/bkg-2.svg"
import Bkg2Big from "../../assets/img/bkg-2-big.svg"
import Bkg3Big from "../../assets/img/bkg-3-big.svg"
import Cell1 from "../../assets/img/cell-1@2x.png"
import Cell2 from "../../assets/img/cell-2@2x.png"
import Cell3 from "../../assets/img/cell-3@2x.png"
import Cell4 from "../../assets/img/cell-4@2x.png"
import Partner from "../../assets/img/partner-0@2x.png"
import Partner1 from "../../assets/img/partner-1@2x.png"
import Partner2 from "../../assets/img/partner-2@2x.png"
import Partner3 from "../../assets/img/partner-3@2x.png"
import Partner4 from "../../assets/img/partner-4@2x.png"
import FloatVideo1 from "../../assets/img/floating-1@2x.png"
import FloatVideo2 from "../../assets/img/floating-2@2x.png"
import FloatVideo3 from "../../assets/img/floating-3@2x.png"
import FloatVideo4 from "../../assets/img/floating-4@2x.png"
import FloatVideo5 from "../../assets/img/floating-5@2x.png"
import FloatVideo6 from "../../assets/img/floating-6@2x.png"
import FloatVideo7 from "../../assets/img/floating-7@2x.png"
import FloatVideo8 from "../../assets/img/floating-8@2x.png"
import FloatVideo9 from "../../assets/img/floating-9@2x.png"
import FloatVideo10 from "../../assets/img/floating-10@2x.png"
import FloatVideo11 from "../../assets/img/floating-11@2x.png"
import FloatVideo12 from "../../assets/img/floating-12@2x.png"
import FloatVideo13 from "../../assets/img/floating-13@2x.png"
import DoctorPinpoint from "../../assets/img/doctor-1@2x.png"
import Float14 from "../../assets/img/floating-14@2x.png"
import Float15 from "../../assets/img/floating-15@2x.png"
import Float16 from "../../assets/img/floating-16@2x.png"
import Float17 from "../../assets/img/floating-17@2x.png"
import Float18 from "../../assets/img/floating-18@2x.png"
import Float19 from "../../assets/img/floating-19@2x.png"
import Float20 from "../../assets/img/floating-20@2x.png"
import Float21 from "../../assets/img/floating-21@2x.png"
import Float22 from "../../assets/img/floating-22@2x.png"
import Float23 from "../../assets/img/floating-23@2x.png"
import Float26 from "../../assets/img/floating-26@2x.png"
import Float27 from "../../assets/img/floating-27@2x.png"
import Float28 from "../../assets/img/floating-28@2x.png"
import Float29 from "../../assets/img/floating-29@2x.png"
import Float30 from "../../assets/img/floating-30@2x.png"
import Float31 from "../../assets/img/floating-31@2x.png"
import Component1 from "../../assets/img/component-1@2x.png"
import Component2 from "../../assets/img/component-2@2x.png"
import Component3 from "../../assets/img/component-3@2x.png"
import Component4 from "../../assets/img/component-4@2x.png"
import Play from "../../assets/img/icon-run.svg"
import Video from "../../assets/AfriMed.mp4"
import "./home.scss"
import Carrousel from "../../components/carrousel"
import AOS from "aos"
import "aos/dist/aos.css"
import { ToastContainer } from "react-toastify"
import Layout from "../../components/layout/layout";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isInterested, setIsInterested] = useState(false);
  const [isVideoClicked, setIsVideoClicked] = useState(false);
  const [isVideoBehind, setIsVideoBehind] = useState(false);

  const videoRef = useRef();

  const toggle = (pInterested = false) => () => {
      setIsInterested(pInterested);
      setIsVisible(!isVisible);
    };

  const playVideo = () => {
      videoRef.current.play();
      setIsVideoClicked(true);
      videoRef.current.onended = () => {
        setIsVideoBehind(false);
        setIsVideoClicked(false);
      }
      setTimeout(() => setIsVideoBehind(true), 300);
  }

  useEffect(() => {
    AOS.init()
  }, []);

  const animation = {
    "data-aos": "fade-up",
    "data-aos-duration": "1000",
    "data-aos-easing": "ease-in-out",
    "data-aos-mirror": "true"
  };

  return (
    <Layout>
      <ToastContainer/>
      {isVisible && <div className="ContactWrapper">
        <div className="Backdrop"/>
        <Contact toggle={toggle} isInterested={isInterested}/>
      </div>}
      <SEO title="AfriMed"
           description={"AfriMed is an on-demand platform for healthcare providers used for disease surveillance, treatment support, epidemic outbreak tracking and chronic disease management."}/>
      <Header toggle={toggle}/>
      <div className="HomeContent">
        <div className="HomeAnchor" id="Home"/>
        <div className="Section MainSection">
          <div className="Title"
               {...animation}
               data-aos-duration="800"
          >
            AfriMed is where Patients, Doctors, Hospitals and
            Medical Services all come together
          </div>

          <img className="Bkg" src={Bkg1} alt={"Main"}
               {...animation}
               data-aos-duration={"2000"}
          />
          <img className="Illustration" src={MainImage} alt={"AfriMed Main Illustration"}
               {...animation}/>
          <a className="DownloadButton" href={'#'} onClick={toggle(true)}>
            I'm interested
          </a>
        </div>

        <div className="Section VideoSection" id="ExploreApp">
          <div className="Title" {...animation} data-aos-offset={"-600"}>
            Discover an innovative method of
            managing your health challenges
          </div>
          <div className="IllustrationWrapper">
            <div className="VideoWrapper">
              <video className="Video" controls ref={videoRef}>
                <source src={Video} type="video/mp4" />
              </video>
              <div className={`Placeholder ${isVideoClicked ? 'Clicked' : ''} ${isVideoBehind ? 'Behind' : ''}`} onClick={playVideo}>
                <div className="Circle">
                  <img className="Img" src={Play} alt="play"/>
                </div>
              </div>
            </div>
            <img className="Float Float-1" src={FloatVideo1} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-2" src={FloatVideo2} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-3" src={FloatVideo3} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-4" src={FloatVideo4} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-5" src={FloatVideo5} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-6" src={FloatVideo6} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-7" src={FloatVideo7} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-8" src={FloatVideo8} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-9" src={FloatVideo9} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-10" src={FloatVideo10} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-11" src={FloatVideo11} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-12" src={FloatVideo12} alt="Float" {...animation} data-aos-offset={"-100"}/>
            <img className="Float Float-13" src={FloatVideo13} alt="Float" {...animation} data-aos-offset={"-100"}/>
          </div>
        </div>

        <div className="Section HowItWorksSection" id="HowItWorks">
          <div className="Title" {...animation} data-aos-mirror={"false"}>How It Works</div>
          <div className="StatementWrapper">
            <img className="Bkg Right Top Big" src={Bkg2Big} alt="Bkg"/>
            <div className="Statement First">
              <div className="Info" {...animation}>
                <div className="SubTitle">
                  Hospitals, Clinics, Doctors
                  & Pharmacies
                </div>
                <div className="Description">
                  Browse through and select medical services nearest to you.
                  They are organized in their speciality -
                  now easy to quickly select the right services at your fingertip.
                </div>
              </div>
              <div className="Image Right Image-1">
                <img className="Bkg Right Top" src={Bkg2} alt="Bkg" {...animation} />
                <img className="Cell-1" src={Cell1} alt="AfriMed Browse Services" {...animation}/>
                <img className="Doctor Doctor-1" src={DoctorPinpoint} alt="Pinpoint" {...animation}
                     data-aos-offset={"100"}
                     data-aos={"fade-down"}/>
                <img className="Doctor Doctor-2" src={DoctorPinpoint} alt="Pinpoint" {...animation}
                     data-aos-offset={"-100"}
                     data-aos={"fade-down"}/>
                <img className="Doctor Doctor-3" src={DoctorPinpoint} alt="Pinpoint" {...animation}
                     data-aos-offset={"100"}
                     data-aos={"fade-down"}/>
                <img className="Float Float-14" src={Float14} alt="Float" {...animation}/>
                <img className="Float Float-15" src={Float15} alt="Float" {...animation}/>
                <img className="Float Float-16" src={Float16} alt="Float" {...animation}/>
                <img className="Float Float-17" src={Float17} alt="Float" {...animation}/>
                <img className="Float Float-18" src={Float18} alt="Float" {...animation}/>
              </div>
            </div>
          </div>
          <div className="StatementWrapper">
            <img className="Bkg Left Big" src={Bkg3Big} alt="Bkg"/>
            <img className="Float Float-27" src={Float27} alt="Float"/>
            <div className="Statement Left">
              <div className="Image Left">
                <img className="Bkg Left" src={Bkg3Big} alt="Bkg"/>
                <img className="Cell-2" src={Cell2} alt="AfriMed Telemedicine" {...animation}/>
                <img className="Float Float-19" src={Float19} alt="Float" {...animation} data-aos-offset={"50"}/>
                <img className="Float Float-20" src={Float20} alt="Float" {...animation} data-aos-offset={"50"}/>
                <img className="Float Float-21" src={Float21} alt="Float" {...animation} data-aos-offset={"50"}/>
                <img className="Float Float-22" src={Float22} alt="Float" {...animation} data-aos-offset={"50"}/>
                <img className="Float Float-23" src={Float23} alt="Float" {...animation} data-aos-offset={"50"}/>
                <img className="Float Float-26" src={Float26} alt="Float" {...animation} data-aos-offset={"50"}/>
                <img className="Float Component-1" src={Component1} alt="Float" {...animation} data-aos={"slide-left"}
                     data-aos-offset={"50"}/>
                <img className="Float Component-2" src={Component2} alt="Float" {...animation}
                     data-aos={"slide-right"} data-aos-offset={"50"}/>
                <img className="Float Component-3" src={Component3} alt="Float" {...animation}
                     data-aos={"slide-right"} data-aos-offset={"50"}/>
                <img className="Float Component-4" src={Component4} alt="Float" {...animation} data-aos={"slide-up"}
                     data-aos-offset={"50"}/>
              </div>
              <div className="Info Center Management" {...animation}>
                <div className="SubTitle">
                  Visits management
                </div>
                <div className="Description">
                  Schedule, reschedule and cancel
                  any visits on the go.
                </div>
                <div className="Line"/>
              </div>
            </div>
          </div>
          <div className="StatementWrapper NoOverflow Wrapper-3">
            <img className="Float Float-31" src={Float31} alt="Float"/>
            <div className="Statement">
              <div className="Info Center Chat" {...animation}>
                <div className="SubTitle">
                  Chat or Video Call
                </div>
                <div className="Description Description-3">
                  Easily video call or chat to your doctor on the go.
                  You can also send images or video
                  evidence of your ailment.
                </div>
                <div className="Line"/>
              </div>
              <div className="Image Right Relative Image-3">
                <img className="Cell-4" src={Cell4} alt="AfriMed Telemedicine Video Chat" {...animation} data-aos={"fade-left"}/>
                <img className="Cell-3" src={Cell3} alt="AfriMed Telemedicine Chat" {...animation} data-aos={"fade-right"}/>
                <img className="Float Float-28" src={Float28} alt="Float" {...animation}/>
                <img className="Float Float-29" src={Float29} alt="Float" {...animation}/>
                <img className="Float Float-30" src={Float30} alt="Float" {...animation}/>
              </div>
            </div>
          </div>
        </div>
        <div className="TestimonialSection" {...animation}>
          <div className="Title">Loved by our users</div>
          <div className="CarouselContainer">
            <Carrousel/>
          </div>
        </div>
        <div className="PartnersWrapper">
          <div className="Title">
            Our trusted partners
          </div>
          <div className="PartnersSection">
            <div className="PartnersHolder">
              <div className="PartnerItem" {...animation}>
                <img className="Img" src={Partner} alt={"Ministry of Health & Child Care"}/>
                <div className="Text">
                  Ministry of Health
                  & Child Care
                </div>
              </div>
              <div className="PartnerItem" {...animation}>
                <img className="Img" src={Partner1} alt={"Nigeria National Health Insurance Scheme (NHIS)"}/>
                <div className="Text">
                  Nigeria National Health Insurance Scheme (NHIS)
                </div>
              </div>
              <div className="PartnerItem" {...animation}>
                <img className="Img" src={Partner2} alt={"Federal Ministry of Health Nigeria"}/>
                <div className="Text">
                  Federal Ministry of Health Nigeria
                </div>
              </div>
              <div className="PartnerItem" {...animation}>
                <img className="Img" src={Partner3} alt={"Ministry of Health Kenya"}/>
                <div className="Text">
                  Ministry of Health Kenya
                </div>
              </div>
              <div className="PartnerItem" {...animation}>
                <img className="Img" src={Partner4} alt={"South African National Department of Health"}/>
                <div className="Text">
                  South African National Department of Health
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer toggleContactUs={toggle} animation={animation}/>
    </Layout>
  )
}

export default Home
